import React from "react";
import { ReactComponent as ImportedComponent } from "../images/codeGuy.svg";
import GitHubIcon from "@material-ui/icons/GitHub";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  guy:{
    marginTop:"1rem",
    height:"39rem",
    width:"39rem",
    [theme.breakpoints.down("xs")]: {
      height: "23rem",
      width: "23rem",
    },
  }

}))

export default function Home() {
  const classes = useStyles();

  return (
    
    <section id="home" style={{ paddingBottom: "2em" }}>
          

        <div style={{ textAlign: "center" }} >
          <ImportedComponent className={classes.guy}/>
          
          {/* <img src={guy} className={classes.guy}></img> */}
          <h1>Akhil Thomas</h1>
          <h2>Junior Developer</h2>
          <div >
            <Grid

              container
              spacing={0}
              style={{
                margin:"auto",
                display: "inline-grid",
                gridTemplateRows: "1fr",
                gridTemplateColumns: "1fr 1fr",
              }}
            >
              <Grid
                item
                // xs={6}
                
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  justifySelf:"end"
                  
                }}
              >
                <a href="https://github.com/akhlthmz" target="_blank" rel="noopener noreferrer">
                  <GitHubIcon style={{ fontSize: "2.4em" }} color="primary" />
                </a>
              </Grid>
              <Grid
                item
                // xs={6}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  justifySelf:"start"
                }}
              >
                <a
                  href="https://www.linkedin.com/in/akhil~thomas/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkedInIcon style={{ fontSize: "3em" }} color="primary" />
                </a>
              </Grid>
            </Grid>
          </div>
        </div>
    </section>
    
  );
}
