import React from "react";
import about from "../images/about.svg";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  section: {
    backgroundColor: "rgb(217, 226, 226)",
    padding: "3em",
  },
  me: {
    height: "30em",
    width: "20em",
  },
  aboutImg: {
    height: "40em",
    width: "40em",
    [theme.breakpoints.down("sm")]: {
      height: "30em",
      width: "30em",
    },
    [theme.breakpoints.down("xs")]: {
      height: "11em",
      width: "11em",
    },
  },
  p: {
    fontFamily: "monospace",
    fontSize: "2em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1em",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.8em",
    },
  },
}));

export default function About() {
  const classes = useStyles();

  return (
    <section id="about" className={classes.section}>
      <h1>About</h1>

      <Grid container spacing={2}>
        <Grid
          item
          xs={6}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={about} alt="about" className={classes.aboutImg} />
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p className={classes.p}>
            Hi! I'm Akhil Thomas.I love to explore technology and develop
            interesting things out of it.I have a background in Electrical
            engineering, Networking and Software testing. I have always been
            fascinated with the internet, how it works and decided to pursue
            this ever challenging field.
          </p>
        </Grid>
      </Grid>
    </section>
  );
}
