import React from "react";
import { makeStyles } from "@material-ui/styles";
import theme from "./ui/theme";
import { ThemeProvider } from "@material-ui/core";
import {
  AppBar,
  Toolbar,
  useScrollTrigger,
  Tabs,
  Tab,
  
} from "@material-ui/core";

function ElevationScroll(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const useStyles = makeStyles((theme) => ({
  tabContainer: {
    marginLeft: "auto",
    color: "white",
    
  },

  tab: {
    fontColor: "secondary",
    fontWeight: 700,
    minWidth: 100,
    marginLeft: "25px",
  },
}));

function Navbar(props) {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const classes = useStyles();

  return (
    <div >
      <ThemeProvider theme={theme}>
        <ElevationScroll>
          <AppBar position="fixed">
            <Toolbar>
              
              <Tabs
                className={classes.tabContainer}
                value={value}
                indicatorColor="secondary"
                onChange={handleChange}
              >
                <Tab className={classes.tab} label="Home" href="#home" />

                <Tab className={classes.tab} label="Skills" href="#skill" />

                <Tab
                  className={classes.tab}
                  label="Projects"
                  href="#projects"
                />
                <Tab className={classes.tab} label="About" href="#about" />
              </Tabs>
            </Toolbar>
          </AppBar>
        </ElevationScroll>
      </ThemeProvider>
    </div>
  );
}

export default Navbar;
