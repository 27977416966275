import React from "react";
import fixit from "../images/fixit.png"
import heroku from "../images/heroku.svg"
import myReads from "../images/MyReads.png";
import wouldYouRather from "../images/wouldYouRather.png";
import testing from "../images/testing.png";
import selenium from "../images/selenium.svg";
import testNG from "../images/testNG.png";
import react from "../images/react.svg";
import link from "../images/link.png";
import material from "../images/skills/material.svg"
import mongo from "../images/skills/mongo.svg"
import firebase from "../images/firebase.svg";
import node1 from "../images/node1.svg";
import redux from "../images/redux.svg";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Paper, ButtonBase, Box,Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    margin: "auto",
    
  },
  projectImage: {
    height: "15em",
    width: "25em",
    [theme.breakpoints.down("sm")]: {
      height: "13em",
      width: "20em",
    },
    [theme.breakpoints.down("xs")]: {
      height: "13em",
      width: "20.85em",
    },
  },
  paper: {
    backgroundColor:"rgb(60, 60, 60)",
    padding: "2em",
    margin: "auto",
    marginTop: "10px",
    marginBottom: "20px",
  },

  h1: {
    padding: "1em",
  },
  section: {
    paddingBottom: "5em",
  },
  h2: {
    textAlign: "center",
    fontFamily: "Roboto",
    backgroundColor: "rgb(217, 226, 226)",
    margin: "0,auto",
    padding: "0.5em",
    marginBottom: "10px",
  },
  description: {
    
    color: "white",
    margin: 0,
    marginTop: "1.5em",
    fontFamily: "Helvetica Neue",
  },
  link: {
    float: "right",
    height: "1.5em",
    width: "1.5em",
  },
  firebase: {
    height: "3.5em",
    width: "3.5em",
    paddingBottom: "0.25em",
    
  },
  node:{
    height: "3em",
    width: "3em",
    paddingBottom: "0.35em",
    paddingLeft:"0.35em"

  },
  redux: {
    height: "3.3em",
    width: "3.3em",
    paddingBottom: "0.24em",
  },
  selenium: {
    height: "3.3em",
    width: "3.3em",
  },
  testNG: {
    height: "3em",
    width: "5em",
    marginLeft: "5px",
  },
  item2:{
    backgroundColor:"rgb(60, 60, 60)",
    padding:"1rem"
  }
}));

export default function Projects() {
  const classes = useStyles();

  return (
    <section id="projects" className={classes.section}>
      <Container component="main" maxWidth="lg">
      <h1 className={classes.h1}>Projects</h1>
{/* First project */}
      <div className={classes.root}>
        <Paper className={classes.paper} elevation={5}>
          <h2 className={classes.h2}>
            Bug tracking app
            <a href="https://shielded-escarpment-69533.herokuapp.com/" target="_blank" rel="noopener noreferrer">
              <img src={link} alt="link" className={classes.link} />
            </a>
          </h2>

          <Grid container spacing={4}>
            <Grid
              item
              style={{
                borderRightColor: "solid black 1px",
                margin: "auto 0px",
              }}
            >
              
              <ButtonBase
                className={classes.image}
                style={{
                  paddingRight: "20px",
                }}
              >
                <img
                  style={{ margin: 0 }}
                  className={classes.projectImage}
                  alt="complex"
                  src={fixit}
                />
              </ButtonBase>
              
            </Grid>
            <Grid
              item
              xs={12}
              sm
              container
              style={{ paddingLeft: "20px",  }}
            >
              <Grid item xs container direction="column" >
                <Grid item xs className={classes.item2}>
                  {" "}
                  <p className={classes.description}>
                    {`Bug tracking app is a web application
                     in which the user can create projects
                     and distribute issues across teammates.
                     Users get an email notification when they 
                     are added to a project or assigned to a bug.
                     This project is built with MERN stack and deployed on heroku. `}
                  </p>
                  
                    <Box letterSpacing={5}>
                      <h2 style={{ marginTop: "1.5em" }}> 
                      <Typography>
                      TOOLS:
                      </Typography>
                      </h2>
                    </Box>
               
                  <img src={react} alt="react logo" />
                  <img src={redux} alt="redux logo" className={classes.redux} />

                  <img
                    src={node1}
                    alt="node logo"
                    className={classes.node}
                  />
                   <img
                    src={mongo}
                    alt="mongodb logo"
                    className={classes.firebase}
                  />
                   <img
                    src={material}
                    alt="material logo"
                    className={classes.firebase}
                  />
                  <img
                    src={heroku}
                    alt="heroku logo"
                    className={classes.firebase}
                  />
                  
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </div>

         {/* Second project */}

      <div className={classes.root}>
        <Paper className={classes.paper} elevation={5}>
          <h2 className={classes.h2}>
            Would You Rather
            <a href="https://would-you-rather-a70af.web.app/" target="_blank" rel="noopener noreferrer">
              <img src={link} alt="link" className={classes.link} />
            </a>
          </h2>

          <Grid container spacing={4}>
            <Grid
              item
              style={{
                borderRightColor: "solid black 1px",
                margin: "auto 0px",
              }}
            >
              
              <ButtonBase
                className={classes.image}
                style={{
                  paddingRight: "20px",
                }}
              >
                <img
                
                  style={{ margin: 0 }}
                  className={classes.projectImage}
                  alt="complex"
                  src={wouldYouRather}
                />
              </ButtonBase>
              
            </Grid>
            <Grid
              item
              // xs={12}
              sm
              container
              style={{ paddingLeft: "20px", }}
            >
              <Grid item xs container direction="column" >
                <Grid item xs className={classes.item2}>
                  {" "}
                  <p className={classes.description}>
                    {`Would You Rather is a web app that lets a user play the
                    “Would You Rather?” game. The game goes like this:  A user is asked a question in the form: “Would you
                    rather (option 1) or (option 2) ?”. Answering "neither" or
                    "both" is against the rules.`}
                  </p>
                  
                    <Box letterSpacing={5}>
                      <h2 style={{ marginTop: "1.5em" }}>
                      <Typography>
                        TOOLS:
                        </Typography>
                        </h2>
                    </Box>
                  
                  <img src={react} alt="react logo" />
                  <img src={redux} alt="redux logo" className={classes.redux} />
                  <img
                    src={material}
                    alt="material logo"
                    className={classes.firebase}
                  />
                  <img
                    src={firebase}
                    alt="firebase logo"
                    className={classes.firebase}
                  />

                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </div>
      {/* Third project */}
      <div className={classes.root}>
        <Paper className={classes.paper} elevation={5}>
          <h2 className={classes.h2}>
            MyReads
            <a href="https://my-reads-32e4c.web.app" target="_blank" rel="noopener noreferrer">
              <img src={link} alt="link" className={classes.link} />
            </a>
          </h2>

          <Grid container spacing={2}>
            <Grid
              item
              style={{
                borderRightColor: "solid black 1px",
                margin: "auto 0px",
              }}
            >
              <ButtonBase
                className={classes.image}
                style={{
                  paddingRight: "20px",
                }}
              >
                <img
                  style={{ margin: 0 }}
                  className={classes.projectImage}
                  alt="complex"
                  src={myReads}
                />
              </ButtonBase>
            </Grid>
            <Grid
              item
              xs={12}
              sm
              container
              style={{ paddingLeft: "20px",}}
            >
              <Grid item xs container direction="column" >
                <Grid item xs className={classes.item2}>
                  {" "}
                  <p className={classes.description}>
                    {`
                  MyReads app allow us to arrange books in three different
                  shelves that are "Currently Reading" , "Want To Read" and
                  "Read". We can also search for new or existing books and
                  categorize them accordingly.`}
                  </p>
                  
                    <Box letterSpacing={5}>
                      <h2 style={{ marginTop: "1.5em" }}>
                        
                      <Typography>TOOLS:
                      </Typography>
                      </h2>
                    </Box>
                  
                  <img src={react} alt="react" />
                  <img
                    src={firebase}
                    alt="firebase"
                    className={classes.firebase}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </div>

      {/* Fourth Project */}

      <div className={classes.root}>
        <Paper className={classes.paper} elevation={5}>
          <h2 className={classes.h2}>Test Automation</h2>

          <Grid container spacing={2}>
            <Grid
              item
              style={{
                borderRightColor: "solid black 1px",
                margin: "auto 0px",
              }}
            >
              <ButtonBase
                className={classes.image}
                style={{
                  paddingRight: "20px",
                }}
              >
                <img
                  style={{ margin: 0 }}
                  className={classes.projectImage}
                  alt="complex"
                  src={testing}
                />
              </ButtonBase>
            </Grid>
            <Grid
              item
              xs={12}
              sm
              container
              style={{ paddingLeft: "20px", }}
            >
              <Grid item xs container direction="column" >
                <Grid item xs className={classes.item2}>
                  {" "}
                  <p className={classes.description}>
                    {`
                  In this project I wrote and executed test scripts using Selenium, POM (Page Object Model) 
                  and TestNG frameworks for the website “http://automationpractice.com/index.php”.`}
                  </p>
                  
                    <Box letterSpacing={5}>
                      <h2 style={{ marginTop: "1.5em" }}>
                      <Typography>TOOLS:
                      </Typography>
                      </h2>
                    </Box>
                  
                  <img
                    className={classes.selenium}
                    src={selenium}
                    alt="selenium"
                  />
                  <img className={classes.testNG} src={testNG} alt="selenium" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </div>
      </Container>
    </section>
  );
}
