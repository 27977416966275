import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {Grid,Container} from "@material-ui/core";

import js from "../images/skills/js.svg";
import react from "../images/react.svg";
import redux from "../images/skills/redux.svg";
import html from "../images/skills/html.svg";
import css from "../images/skills/css.svg";
import node from "../images/skills/node.svg";
import git from "../images/skills/git.svg";
import express from "../images/skills/express.svg";
import mongo from "../images/skills/mongo.svg";
import material from "../images/skills/material.svg";
import python from "../images/skills/python.svg";
import django from "../images/skills/django.svg";
import selenium from "../images/skills/selenium.svg";
import npm from "../images/skills/npm.svg";
import jira from "../images/skills/jira.svg";

const useStyles = makeStyles((theme) => ({
  // root: {
  //   minWidth: 150,
  //   background: "linear-gradient(45deg, #919ccc 30%, #588c99 90%)",
  //   opacity: "70%",
  //   border: 0,
  //   borderRadius: 3,
  //   boxShadow: "0 3px 5px 2px rgba(33, 203, 243, .3)",
  //   color: "white",
  //   height: 65,
  //   width: 100,
  //   padding: "0 30px",
  //   [theme.breakpoints.down("sm")]: {
  //     minWidth: 100,
  //     height: 50,
  //   },
  //   [theme.breakpoints.down("xs")]: {
  //     height: 50,
  //   },
  // },
  gridContainer: {
    display: "inline-grid",
    gridTemplateRows: "1fr",
    gridTemplateColumns: "1fr 1fr 1fr 1fr  1fr ",
    [theme.breakpoints.down("sm")]: {
      display: "inline-grid",
      gridTemplateRows: "1fr",
      gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
    },
    [theme.breakpoints.down("xs")]: {
      display: "inline-grid",
      gridTemplateRows: "1fr",
      gridTemplateColumns: "1fr 1fr ifr ifr   ",
    },
  },
  content: {
    textAlign: "center",
    fontFamily: "monospace",
  },
  h1: {
    padding: "1em",

  },
  div1: {
    margin: "auto",
    width: "80%",
  },
  section: {
    backgroundColor: "rgb(217, 226, 226)",
    paddingBottom: "10em",
    marginTop: "5em",
  },
  js: {
    height: "7em",
    width: "7em",
    [theme.breakpoints.down("sm")]: {
      height: "3em",
      width: "3em",
      display: "inline-grid",
      
    },
    [theme.breakpoints.down("xs")]: {
      height: "1.5em",
      width: "1.5em",
      display: "inline-grid",
      
    },
  },
}));
const skills = [
  js,
  react,
  redux,
  html,
  css,
  node,
  mongo,
  express,
  git,
  npm,
  python,
  django,
  material,
  selenium,
  jira,
];

export default function Skills() {
  const classes = useStyles();
  return (
    <section id="skill" className={classes.section}>
      <Container component="main" maxWidth="lg">

      <div className={classes.div1}>
        <h1 className={classes.h1}>Skills</h1>
        <Grid container spacing={2} className={classes.gridContainer}>
          {skills.map((skill,index) => (
            <Grid
            key={index}
              item
              
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img className={classes.js} src={skill} alt="skill" />
            </Grid>
          ))}
        </Grid>
      </div>
      </Container>
    </section>
  );
}
